.drop-file-input {
  position: relative;
  border: 2px dashed grey;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
  border-color: rgb(255, 192, 1);
}

.drop-file-input__label {
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  transition: opacity 0.3s ease;
}
