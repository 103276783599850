/* Spinner.css */
.rotating-image {
  animation: rotate 2s linear infinite; /* Adjust duration and timing function as needed */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
